.info {
  pointer-events: all;

  .responsibilities {
    max-height: 0;
    overflow-y: hidden;
    pointer-events: none;
    transition: var(--default-transition);
    padding-right: 1rem;

    &.expanded {
      pointer-events: all;
      max-height: 60rem;
    }
  }

  .responsibility {
    margin-top: 0.5rem;
    padding-left: 1.25rem;
    text-indent: -1.25rem;
  }
}
