.container-page {
  position: relative;
  overflow-x: hidden !important;
  .project {
    border: 2rem solid #fff;
    --body-width: 800px;
    padding-right: 18rem;
    padding-left: 15rem;

    padding-left: calc(50vw - --body-width / 2 + 18rem);
    transition: var(--default-transition);

    img {
      border-radius: var(--default-corner-rounding);
    }

    .body-content {
      max-width: var(--body-width);

      p {
        text-indent: 2.5rem;
      }

      h2 {
        padding-top: 1.75rem;
        padding-bottom: 1rem;
        color: var(--color-primary);
        font-size: var(--medium-header);
      }
    }

    .title {
      position: static;
      padding-top: 8rem;
      z-index: 1010;
      transition: var(--default-transition);
      font-size: var(--big-header);
      width: 100%;
    }

    .section-nav {
      position: static;
      margin-top: 3rem;
      right: -30rem;
      transition: var(--default-transition);
      a {
        font-size: 1.25rem;
        padding: 0.5rem 0;
        margin-right: 1.5rem;
        text-decoration: none;
        color: var(--color-body);
        transition:
          border var(--default-transition),
          margin(--default-transition);

        &.active,
        &:hover {
          font-weight: bold;
          color: var(--color-primary);
          border-bottom: 5px solid var(--color-primary);
        }
      }
    }

    .section-spacer {
      position: static;
      margin-top: 0;
      height: 0.25rem;
    }

    .space-intro {
      transition: var(--default-transition);
      padding-top: 2.5rem;
    }
  }

  .info-nav-panel {
    position: fixed;
    top: 50%;
    transform: translate(0, -50%);
    padding-left: 4rem;
    width: calc(18rem - var(--default-margin));
    transition: var(--default-transition);
    opacity: 100;
    h6 {
      color: var(--color-fade);
      font-size: var(--body-font-size);
    }
    .more-projects {
      padding-right: 1rem;
      max-height: 50vh;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 5px !important;
      }
      a {
        text-decoration: none;
        transition: var(--default-transition);
        color: var(--color-body);
        &:hover {
          color: var(--color-primary);
        }
        &.active {
          color: var(--color-primary);
          font-weight: bold;
        }
      }
    }
  }
  .nav-button {
    opacity: 0;
    pointer-events: none;
    position: fixed;
    margin: 0.5rem;
    margin-right: -10rem;
    right: 1rem;
    bottom: 1rem;
    color: #fff;
    background-color: var(--color-primary);
    border: none;
    width: 4rem;
    height: 4rem;
    font-size: var(--small-header);
    transform: scale(1);
    border-radius: 2rem;
    transition: var(--default-transition);
    box-shadow: var(--shadow-tight);

    &:hover {
      color: var(--color-primary);
      background-color: #fff;
    }
  }

  @media (min-width: 992px) {
    &.stuck {
      .info-nav-panel {
        top: 12rem;
        transform: translate(0, 0);
        position: fixed;
      }

      .more-projects {
        padding-right: 1rem;
        overflow-y: scroll;
        &::-webkit-scrollbar {
          width: 5px !important;
        }
      }

      .section-nav {
        position: fixed;
        right: 1rem;
        top: 8rem;
        padding: 0.75rem 2rem;
        text-align: right;
        a {
          display: flex;
          flex-direction: column;
          font-size: 1.25rem;
          padding: 0.25rem 0;
          margin-top: 0.5rem;
          text-decoration: none;

          &:hover,
          &.active {
            margin-top: calc(0.5rem - 5px);
          }
        }
      }

      .section-spacer {
        position: static;
        margin-top: 3rem;
        height: 2.5rem;
      }
    }
  }

  @media (max-width: 991px) {
    .project {
      h2 {
        font-size: var(--small-header) !important;
      }
      .title {
        padding-top: 8rem;
        top: 4rem;
        font-size: var(--medium-header);
      }

      padding: 0 8.333333333333%;
    }

    .nav-button {
      pointer-events: all;
      opacity: 100;
      margin-right: 0.5rem;
    }
    .section-nav {
      display: none;
    }

    .info-nav-panel {
      position: fixed;
      opacity: 0;
      pointer-events: none;
      margin-left: -15rem;
      &.expanded {
        top: calc(50% + 3rem);
        margin-left: 0;
        width: 15rem;
        left: 1rem;
        padding: 4rem 2rem;
        transform: translate(-1rem, -50%);
        opacity: 100;
        pointer-events: all;
        background-color: var(--color-background);
        box-shadow: var(--shadow-tight);
        border-radius: 0 var(--default-corner-rounding)
          var(--default-corner-rounding) 0;
      }
      .more-projects {
        max-height: 50vh;
      }
    }
  }
}
