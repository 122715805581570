.cursor-tooltip {
  color: #000;

  .tooltiptext {
    visibility: hidden;
    min-width: 140px;
    max-width: 200px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 8px;
    position: fixed;
    z-index: 1010;
    opacity: 0;
    transition: opacity 0.3s;
    pointer-events: none;
  }

  &:hover {
    cursor: pointer !important;
    .tooltiptext {
      visibility: visible;
      opacity: 1;
    }
  }
}
