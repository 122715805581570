.toast-alert {
  position: fixed;
  opacity: 100;
  bottom: 10vh;
  transform: translate(-50%, 0);
  left: 50%;
  padding: 1rem 2rem;
  background-color: #fff;
  border-radius: 15px;
  display: flex;
  align-items: center;
  box-shadow: 0px 75px 200px -5px rgba(102, 102, 102, 0.56);
  gap: 20px;
  z-index: 1000000;

  &.hide {
    opacity: 0;
    transition: 0.5s;
    transform: translate(-50%, 50vh);
    animation-timing-function: ease-out;
  }

  &.show {
    opacity: 100;
    transition: 0.5s;
    transform: translate(-50%, 0);
    animation-timing-function: ease-in;
  }
}

.t-affirm .toast-icon {
  color: var(--color-success);
}

.t-negate .toast-icon {
  color: var(--color-error);
}

.t-email .toast-icon {
  color: var(--color-info);
}

.t-notify .toast-icon {
  color: var(--color-info);
}

.t-info .toast-icon {
  color: var(--color-info);
}
